import * as actionTypes from '../utils/actionTypes';

const initialAppState = {
  messages: [],
  currentPage: 0,
  hasMore: true,
};

const Message = (state = initialAppState, action) => {
   switch (action.type) {
    case actionTypes.INIT_MESSAGE:
      return {...state, messages: action.messages};
    case actionTypes.MERGE_MESSAGE:
      return {...state,
        messages: state.messages.concat(action.payload.messages),
        currentPage: action.payload.page,
        hasMore: action.payload.hasMore
      };
    case actionTypes.FINISH_LOADING:
      return {...state,
        hasMore: false
      };
    case actionTypes.UPDATE_MESSAGE:
      return {...state,
        messages: action.payload.messages.concat(state.messages),
      };
    default:
      return state
  }
};

export default Message;