import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import user from './user';
import message from './message';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  message
})

export default createRootReducer;