import React, {Component} from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home.js';
import Signup from './pages/user/Signup.js';
import Login from './pages/user/Login.js';
import Timeline from './pages/message/Timeline.js';
import Settings from './pages/user/Settings';
import NotFound  from './pages/others/NotFound';
import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from './route/PrivateRoute';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './store/configureStore';
import AuthCallback from './pages/user/AuthCallback';

const store = configureStore()

class App extends Component {
  render(){
    return(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <div className="app-wrapper">
            <Header />
              <div className="app-container">
                <Switch>
                  <Route exact path='/' component={Home} />
                  <Route exact path='/signup' component={Signup} />
                  <Route exact path='/login' component={Login} />
                  <Route exact path='/auth/callback' component={AuthCallback} />

                  {/* user */}
                  <PrivateRoute path="/user/settings" component={Settings} />

                  {/* message */}
                  <PrivateRoute exact path='/message/timeline' component={Timeline} />

                  {/* NotFound */}
                  <Route path='*' component={NotFound} />
                </Switch>
              </div>
            <Footer />
          </div>
        </ConnectedRouter>
      </Provider>
    )
  }
}

export default App;
