import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export default class Home extends Component {
  render(){
    return(
      <div className="center-screen">
        <p>Get started with</p>
        <Link className="md-btn" to="/login">Sign in</Link>
        <Link className="md-btn" to="/signup">Sign up</Link>
      </div>
    )
  }
}