import React, {Component} from 'react';
import Message from '../../components/message/Message';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Utility from '../../utils/utility';
import LoadingGif from '../../images/loading.gif';
import InfiniteScroll from 'react-infinite-scroller';
import ActionCable from 'actioncable';

class Timeline extends Component {
  constructor(props){
    super(props);
    this.state = {
      error: "",
    }
  }

  componentWillMount(){
    this.setupWebsocket()
  }

  setupWebsocket(){
    let accessToken = localStorage.getItem('access_token');
    const target = `${process.env.REACT_APP_ACTION_CABLE_ENDPOINT}?token=${accessToken}`

    let cable = ActionCable.createConsumer(target)

    let _this = this
    cable.subscriptions.create('UserChannel', {
      received(data) {
        if(data){
          if(data.name === "updateMessage"){
            const payload = {
              messages: data.messages
            }
            _this.props.updateMessage(payload)
          }
        }
      }
    });
  }

  fetchMessages(){
    const nextPage = this.props.currentPage + 1
    const params = Utility.getWithTokenParams('/api/message', {page: nextPage})

    axios(params)
    .then((response)=>{
      this.props.mergeMessage(response.data)
    })
    .catch((error)=>{
      this.props.finishLoading()
      this.setState({error: "メッセージの取得に失敗しました"})
    })
  }

  renderMessages(){
    if(this.props.messages && this.props.messages.length > 0){
      return this.props.messages.map(message => <Message key={message.id} message={message}/>)
    } else {
      return "メッセージを取得中です"
    }
  }

  render(){
    return(
      <InfiniteScroll
        pageStart={0}
        loadMore={this.fetchMessages.bind(this)}
        hasMore={this.props.hasMore}
        initialLoad={true}
        loader={<img key="loading" src={LoadingGif} className="loading-icon" alt="loading icon" />}>
        <div className="center-top">
          <p className="error-msg">{this.state.error}</p>
          <ul>
             {this.renderMessages()}
          </ul>
        </div>
      </InfiniteScroll>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  messages: state.message.messages,
  currentPage: state.message.currentPage,
  hasMore: state.message.hasMore
})

const mapDispatchToProps = (dispatch) => (bindActionCreators(actions, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Timeline)