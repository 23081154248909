import React, {Component} from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { bindActionCreators } from 'redux';
import Utility from '../../utils/utility';

class AuthCallback extends Component{
  componentDidMount() {
    // パラメータに含まれる場合はそれを使う
    const query = queryString.parse(this.props.location.search);
    const errorMessage = query.error_message

    if(!errorMessage){
      localStorage.setItem('access_token', query.access_token);
      localStorage.setItem('refresh_token', query.refresh_token);
    }


    if(query){
      const params = Utility.postWithTokenParams('/api/users/find_current_user')
      axios(params)
      .then((response)=>{
        this.props.history.push("/message/timeline")

        if(response.data.result == "success"){
          this.props.initUser(response.data.user)

          const return_path = localStorage.getItem('oauth_return_path')

          if(return_path){
            localStorage.removeItem('oauth_return_path')
            this.props.history.push({
              pathname: return_path,
              state: { errorMessage }
            })
          } else {
            this.props.history.push({
              pathname:"/message/timeline",
              state: {errorMessage}
            })
          }
        } else {
          this.props.history.push({
            pathname:"/login",
            state: {errorMessage}
          })
        }
      })
      .catch((error)=>{
        this.props.history.push({
          pathname: "/login",
          state:{errorMessage}
        })
      })
    }
  }

  render(){
    return(
      <div></div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = (dispatch) => (bindActionCreators(actions, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallback)