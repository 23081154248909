import React, {Component} from 'react';

export default class NotFound extends Component {
  render(){
    return(
      <div>
        ページが見つかりませんでした。
      </div>
    )
  }
}