import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import GoogleIcon  from '../../images/google_login_logo.png';
import FacebookIcon from '../../images/facebook_login_logo.png';
import ChatworkIcon from '../../images/chatwork_login_logo.png';
import axios from 'axios';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { bindActionCreators } from 'redux';

class Login extends Component {
  constructor(props){
    super(props)
    this.state = {
      email:"",
      password:"",
      error:""
    }
  }

  componentDidMount(){
    const routerState = this.props.location.state

    if(routerState){
      const errorMessage = this.props.location.state.errorMessage
      if(errorMessage){
        this.setState({
          error: errorMessage
        })
      }
    }
  }

  handleChange(e){
    this.setState({
      [e.target.name]:e.target.value
    })
  }

  handleSubmit(e){
    e.preventDefault()
    const _this = this
    const params = {
      email: this.state.email,
      password: this.state.password
    }
    axios.post('/api/users/login', params)
    .then(function (response) {
      const token = response.data.token
      localStorage.setItem('access_token', token.access_token)
      localStorage.setItem('refresh_token', token.refresh_token)
      _this.props.history.push('/message/timeline')
    })
    .catch(function (error) {
      _this.setState({
        error:"ログインに失敗しました。"
      })
    });
  }

  render(){
    return(
      <div className="center-screen">
        <p>Fixing Your message apps with</p>
        <a href="/oauth/google"><img className="sns-login-btn" src={GoogleIcon} alt="google-icon"/></a>
        <a href="/oauth/slack"><img className="sns-login-btn" src="https://platform.slack-edge.com/img/sign_in_with_slack.png" alt="slack-icon"/></a>
        {/* <a href="/oauth/facebook"><img className="sns-login-btn" src={FacebookIcon} alt="facebook-icon"/></a> */}
        <a href="/oauth/chatwork"><img className="sns-login-btn chatwork-login-btn" src={ChatworkIcon} alt="chatwork-icon"/></a>
        <p>or Sign in with your email</p>
        <form className="signup-form" onSubmit={(e)=>this.handleSubmit(e)}>
          <div className="form-container">
            <p>email</p>
            <input required type="email" name="email" value={this.state.email} onChange={(e) => this.handleChange(e)}/>
          </div>
          <div className="form-container">
            <p>password</p>
            <input required type="password" name="password" value={this.state.password} onChange={(e) => this.handleChange(e)}/>
          </div>
          {this.state.error?(<p className="error-msg">{this.state.error}</p>):null}
          <div className="form-container">
            <input type="submit" className="basic-submit-btn" value="ログイン"/>
          </div>
        </form>
        <Link to="/signup">新規登録はこちら</Link>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => (bindActionCreators(actions, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Login)