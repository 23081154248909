import React, {Component} from 'react';
import { Redirect, Route } from 'react-router-dom';
import * as actions from '../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import axios from 'axios';
import Utility from '../utils/utility'

class PrivateRoute extends Component{
  constructor(props){
    super(props);
    this.state={
      loading: true
    }
  }

  componentDidMount(){
    if(!this.props.userState.user){
      const params = Utility.postWithTokenParams('/api/users/find_current_user')
      axios(params)
      .then((response)=>{
        if(response.data.result === "success"){
          this.props.initUser(response.data.user)
        }
        this.setState({
          loading: false
        })

      })
      .catch((error)=>{
        this.setState({
          loading: false
        })
      })
    }
  }

  render(){
    if(this.state.loading){
      return <Route path="/loading" component={()=>(<div>Loading</div>)} />
    } else if( this.props.userState.user ){
      return( <Route path={this.props.path} component={this.props.component} /> );
    }
    else{
      return( <Redirect to="/login" /> );
    }
  }
}

const mapStateToProps = state => ({
  userState: state.user
})

const mapDispatchToProps = (dispatch) => (bindActionCreators(actions, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)