import React, {Component} from 'react';
import Gmailicon from '../../images/gmail-icon.png';
import MessangerIcon from '../../images/facebook-messanger-icon.png';
import SlackIcon from '../../images/slack-icon.png';
import ChatworkIcon from '../../images/chatwork-icon.png';
import SampleUserIcon from '../../images/sample-user.png';
import moment from 'moment';

export default class Message extends Component{
  getProviderIcon(provider) {
    switch(provider){
      case "GmailMessage":
        return Gmailicon;
      case "FacebookMessage":
        return MessangerIcon;
      case "ChatworkMessage":
        return ChatworkIcon;
      case "SlackMessage":
        return SlackIcon;
      default:
        break
    }
  }

  getProviderName(provider) {
    switch(provider){
      case "GmailMessage":
        return "Gmail";
      case "FacebookMessage":
        return "Facebook messanger";
      case "ChatworkMessage":
        return "Chatwork";
      case "SlackMessage":
        return "Slack";
      default:
        break
    }
  }

  renderAccountInfo(message){
    switch(message.type){
      case "GmailMessage":
        return message.gmail
      case "FacebookMessage":
        return "Facebook messanger";
      case "ChatworkMessage":
        return message.chatworkId
      case "SlackMessage":
        return message.slackWorkspaceName
      default:
        break
    }
  }

  getUserIcon(message){
    if(message.serviceUser){
      return message.serviceUser.image
    } else if(message.senderIcon){
      return message.senderIcon
    }

    return SampleUserIcon
  }

  getUserDisplayName(message){
    if(message.serviceUser){
      return message.serviceUser.name
    }

    return message.senderName
  }

  render(){
    const message = this.props.message
    return(
      <li className="message-container">
        <a href={message.detailUrl} target="_blank">
          <div className="message-header">
            <div>
              <img src={this.getProviderIcon(message.type)} className="message-icon"/>
            </div>
            <div className="message-info">
              <p className="message-type">{this.getProviderName(message.type)}</p>
              <p className="message-account-info">{this.renderAccountInfo(message)}</p>
            </div>
          </div>
          <div className="message-header2">
            {message.type == "SlackMessage"?<p className="message-channel-name">#{message.channelName}</p>:null}
          </div>
          <div className="message-header">
            <p className="message-time">{message.sendAt?moment(message.sendAt).format("YYYY/MM/DD HH:mm"):null }</p>
          </div>
          <div className="message-content">
            <div>
              <img className="sender-icon" src={this.getUserIcon(message)}/>
            </div>
            <div className="message-detail">
              <div className="message-detail-info">
                <div className="sender-name">{this.getUserDisplayName(message)}</div>
              </div>
              <p className="message-text">
              {message.content}
              </p>
            </div>
          </div>
        </a>
      </li>
    )
  }
}