import React, {Component} from 'react';
import { amber, green, red, blue } from '@material-ui/core/colors';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent'



export default class OriginalSnackBar extends Component{
  constructor(props){
    super(props);
    this.state = {
      open: false,
      closeByDuration: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.open !== prevState.open && !prevState.closeByDuration){
      return {
        open: nextProps.open
      }
    }

    return null
  }

  getColor(varient){
    switch(varient){
      case 'success':
        return green[600]
      case 'error':
        return red[500]
      case 'info':
        return blue[500]
      case 'warning':
        return  amber[700]
      default:
        return green[600]
    }
  }

  handleClose(){
    this.setState({
      open: false,
      closeByDuration: true
    })
  }

  render(){
    return(
      <Snackbar
        open={this.state.open}
        autoHideDuration={3000}
        onClose={()=>{this.handleClose()}}
        >
          <SnackbarContent
            style={{
              backgroundColor: this.getColor(this.props.variant)
            }}
            message={<span id="alert">{this.props.text}</span>}
            aria-describedby="client-snackbar"
          />
      </Snackbar>
    )
  }
}