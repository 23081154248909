export default class Utility {
  static postWithTokenParams(url, data = {}) {
    let accessToken = localStorage.getItem('access_token');
    return {
      method  : 'POST',
      url     : url,
      headers: {'Authorization': "Bearer "+accessToken},
      data
    }
  }

  static getWithTokenParams(url, params = {}) {
    let accessToken = localStorage.getItem('access_token');
    return {
      method  : 'GET',
      url     : url,
      headers: {'Authorization': "Bearer "+accessToken},
      params
    }
  }
}