import * as actionTypes from '../utils/actionTypes';

export const initMessage = (messages) => ({
  type: actionTypes.INIT_MESSAGE,
  messages,
});

export const mergeMessage = (payload) => ({
  type: actionTypes.MERGE_MESSAGE,
  payload
})

export const finishLoading = () => ({
  type: actionTypes.FINISH_LOADING
})

export const updateMessage = (payload) => ({
  type: actionTypes.UPDATE_MESSAGE,
  payload
})
