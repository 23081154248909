import React, {Component} from 'react';
import Icon from '@material-ui/core/Icon';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { bindActionCreators } from 'redux';

class Header extends Component{
  render(){
    return(
      <header className="header">
        <Link to="/message/timeline" className="header-title">Fixie</Link>
        {this.props.user?(<Link to="/user/settings">
          <Icon className="settings-icon">settings_applications</Icon>
        </Link>):null}
      </header>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user
})

const mapDispatchToProps = (dispatch) => (bindActionCreators(actions, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Header)