import React, {Component} from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { bindActionCreators } from 'redux';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import Utility from '../../utils/utility';
import OriginalSnackBar from '../../components/others/OriginalSnackBar'

class Settings extends Component {
  constructor(props){
    super(props);
    this.state = {
      hasError: false,
      errorText: ""
    }
  }

  logout(){
    const params = Utility.postWithTokenParams('/api/users/logout', {})
    axios(params)
    .then((response)=>{
      if(response.data.result === "success"){
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        this.props.initUser(null)
        this.props.history.push('/')
      }
    })
    .catch((error)=>{
    })
  }

  componentDidMount(){
    const routerState = this.props.location.state

    if(routerState){
      const errorMessage = this.props.location.state.errorMessage
      if(errorMessage){
        this.setState({
          hasError: true,
          errorText: errorMessage
        })
      }
    }
  }

  handleChange(auth){
    const nextStatus = !auth.enabled

    let url
    if(nextStatus){
      url = "/api/users/enabled_oauth"
    } else {
      //連携offにする
      url = "/api/users/disabled_oauth"
    }

    const params = Utility.postWithTokenParams(url, {auth_id: auth.id})

    axios(params)
    .then((response)=>{
      if(response.data.result === "success"){
        this.props.initUser(response.data.user)
      }
    })
    .catch((error)=>{
      // console.log(error)
    })
  }

  connectNewAccount(provider){
    // 新規追加する時に必要
    let path= window.location.pathname;
    localStorage.setItem('oauth_return_path', path)
    let accessToken = localStorage.getItem('access_token');
    window.location.href = `/oauth/connect/${provider}?entry=setting&access_token=${accessToken}`
  }

  renderAccounts(accounts){
    return accounts.map((auth)=>{
      return(
        <li key={auth.service_user_id}>
          <span>{auth.service_user_id}</span>
          <Switch
            checked={auth.enabled}
            onChange={()=>{this.handleChange(auth)}}
            inputProps={{ 'aria-label': "" }}
          />
        </li>
      )
    })
  }

  render(){
    return(
      <div className="">
        <div className="settings-container">
          <h2>Service</h2>
          <div>
            <h3>slack</h3>
            <ul>
            {this.renderAccounts(this.props.user.slackAccounts)}
            </ul>
            <button className="md-btn" style={{marginLeft:0}} onClick={()=>{this.connectNewAccount("slack")}}>Slack workspaceを追加する</button>
          </div>
          <div>
            <h3>Google</h3>
            <ul>
            {this.renderAccounts(this.props.user.googleAccounts)}
            </ul>
            <button className="md-btn" style={{marginLeft:0}} onClick={()=>{this.connectNewAccount("google")}}>Googleアカウントを追加する</button>
          </div>
          <div>
            <h3>Chatwork</h3>
            <ul>
            {this.renderAccounts(this.props.user.chatworkAccounts)}
            </ul>
            <button className="md-btn" style={{marginLeft:0}} onClick={()=>{this.connectNewAccount("chatwork")}}>Chatworkアカウントを追加する</button>
          </div>
        </div>
        {/* {this.renderSlackWorkSpace()} */}
        {/* <div className="settings-container">
          <h2>Chatwork room</h2>
        </div> */}
        <p className="basic-submit-btn margin-top-30" onClick={()=>{this.logout()}}>ログアウト</p>
        <OriginalSnackBar open={this.state.hasError} text={this.state.errorText} variant="error"/>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user
})

const mapDispatchToProps = (dispatch) => (bindActionCreators(actions, dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Settings)