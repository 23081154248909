import React, {Component} from 'react';

export default class Footer extends Component{
  render(){
    return(
      <footer className="footer">
        <ul className="footer-component flex-center">
          <li>About</li>
          <li>Help</li>
          <li>Jobs</li>
          <li>Terms</li>
          <li>Privacy</li>
          <li>Trust</li>
        </ul>
        <div className="footer-component">
          <p>Build your own service and Applets</p>
        </div>
        <div className="footer-component">
          <p>Fixie</p>
        </div>
      </footer>
    )
  }
}