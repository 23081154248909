import * as actionTypes from '../utils/actionTypes';

const initialAppState = {
  user: null
};

const User = (state = initialAppState, action) => {
  if (action.type === actionTypes.INIT_USER) {
    return Object.assign({}, state, {
      user: action.user
    });
  } else {
    return state;
  }
};

export default User;